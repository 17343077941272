import revive_payload_client_gWQsZL6YF7 from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_vite@5.4.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XtJf07UWYP from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_vite@5.4.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cqpFHQ4jnr from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_vite@5.4.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Wv4ssuB5Cz from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_vite@5.4.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_skUmJLpOmS from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_vite@5.4.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_69u5JH078V from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_vite@5.4.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_7rgxk8002c from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_vite@5.4.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_fkap2JOJzn from "/opt/build/repo/front/node_modules/.pnpm/@pinia+nuxt@0.5.3_rollup@4.20.0_vue@3.4.37/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/front/.nuxt/components.plugin.mjs";
import prefetch_client_7YTENlRIhT from "/opt/build/repo/front/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_vite@5.4.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_f4gtl4N964 from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.37/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_q6ELgViU4R from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.37/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_DSCCWQfetb from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+prismic@3.3.2_rollup@4.20.0_vite@5.4.0_vue@3.4.37/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.js";
import plugin_IvUHMxwGUW from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+prismic@3.3.2_rollup@4.20.0_vite@5.4.0_vue@3.4.37/node_modules/@nuxtjs/prismic/dist/runtime/plugin.js";
export default [
  revive_payload_client_gWQsZL6YF7,
  unhead_XtJf07UWYP,
  router_cqpFHQ4jnr,
  payload_client_Wv4ssuB5Cz,
  navigation_repaint_client_skUmJLpOmS,
  check_outdated_build_client_69u5JH078V,
  chunk_reload_client_7rgxk8002c,
  plugin_vue3_fkap2JOJzn,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7YTENlRIhT,
  switch_locale_path_ssr_f4gtl4N964,
  i18n_q6ELgViU4R,
  plugin_client_DSCCWQfetb,
  plugin_IvUHMxwGUW
]