import { default as PrismicPreviewQkS9NxbFALMeta } from "/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+prismic@3.3.2_rollup@4.20.0_vite@5.4.0_vue@3.4.37/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue?macro=true";
import { default as indexK09NvBaU2LMeta } from "/opt/build/repo/front/pages/index.vue?macro=true";
import { default as slice_45simulatorXrK22py1fHMeta } from "/opt/build/repo/front/pages/slice-simulator.vue?macro=true";
export default [
  {
    name: "prismic-preview___ko",
    path: "/preview",
    component: () => import("/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+prismic@3.3.2_rollup@4.20.0_vite@5.4.0_vue@3.4.37/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue").then(m => m.default || m)
  },
  {
    name: "prismic-preview___de",
    path: "/de/preview",
    component: () => import("/opt/build/repo/front/node_modules/.pnpm/@nuxtjs+prismic@3.3.2_rollup@4.20.0_vite@5.4.0_vue@3.4.37/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue").then(m => m.default || m)
  },
  {
    name: "index___ko",
    path: "/",
    component: () => import("/opt/build/repo/front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/opt/build/repo/front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "slice-simulator___ko",
    path: "/slice-simulator",
    component: () => import("/opt/build/repo/front/pages/slice-simulator.vue").then(m => m.default || m)
  },
  {
    name: "slice-simulator___de",
    path: "/de/slice-simulator",
    component: () => import("/opt/build/repo/front/pages/slice-simulator.vue").then(m => m.default || m)
  }
]